import React, { useEffect } from "react"

import { navigate } from "../services/routes" // "gatsby"
import { getSession } from "../services/auth"
import routes from "../services/routes"

export const RequireNoUser = ({children}) => {
	const session = getSession();
	if (session.user) {
		if (session.user.confirmed) {
			navigate(routes.account.index, {replace:true});
		} else {
			navigate(routes.confirm, {replace:true});
		}
		return null;
	}
	return (<>{children}</>);
}

