
export function isEmailValid(email) {
	// Email regex obtained from http://emailregex.com/
	var emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	return emailRegEx.test(email);
}

export function checkPassword(password) {
	const RULES = [
		{
			check: (password) => password.length >= 10,
			reason: 'Your password must be at least 10 characters long. (try '+
			'<a href="https://xkcd.com/936/" target="_blank">this</a> strategy)',
		},
		{
			check: (password) => password.toLowerCase() !== "correcthorsebatterystaple",
			reason: 'Your password cannot actually be "correcthorsebatterystaple"!',
		}
	];
	for (let rule of RULES) {
		if (!rule.check(password)) {
			return { valid: false, reason: rule.reason };
		}
	}
	return { valid: true };
}